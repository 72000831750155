export const isArrayLength = arr => {
  // Check if the input parameter is an array and has a length greater than zero.
  return Array.isArray(arr) && (arr.length > 0 ?? false);
};

// Function to calculate a bounding box (minLon, minLat, maxLon, maxLat)
export const calculateBoundingBox = (lat, lng, radius) => {
  const toRadians = Math.PI / 180;
  const toDegrees = 180 / Math.PI;
  const R = 6371; // Earth's radius in km

  const latRad = lat * toRadians;
  const radiusByR = radius / R;

  const latChange = radiusByR * toDegrees;
  const lonChange = (radiusByR * toDegrees) / Math.cos(latRad);

  return {
    minLat: lat - latChange,
    maxLat: lat + latChange,
    minLon: lng - lonChange,
    maxLon: lng + lonChange,
  };
};


export function getAddressFromSearch(history) {
  const searchParams = new URLSearchParams(history.location.search);
  return searchParams.get('address');
}

export const getUserType = user => {
  return user?.attributes?.profile?.publicData?.userType
};
