import { ListingState } from '../enums/enums';
import { isArrayLength } from './genericHelpers';

export const updateListingsData = async (sdk, params) => {
  const { geolocation, ...rest } = params || {};
  try {
    const listingsArray = [];

    // Fetch listings (PUBLISHED state) across all pages
    const response = await sdk.ownListings.query({
      state: ListingState.DRAFT,
    });

    const numberOfPages = response.data.meta.totalPages;

    // Fetch all listings across pages
    for (let i = 1; i <= numberOfPages; i++) {
      const {
        data: { data = [] },
      } = await sdk.ownListings.query({
        page: i,
        state: ListingState.DRAFT,
      });

      // Add listings to the array
      if (data.length) {
        listingsArray.push(...data);
      }
    }

    if (!isArrayLength(listingsArray)) {
      console.log('No listings to update.');
      return;
    }

    // Update listings in batches with delay between batches
    const batchSize = 40;
    const delayBetweenBatches = 40000; // 40 seconds

    for (let i = 0; i < listingsArray.length; i += batchSize) {
      const batch = listingsArray.slice(i, i + batchSize);

      await Promise.all(
        batch.map(async listing => {
          try {
            console.log(`Updating listing ID: ${listing.id}`);
            await sdk.ownListings.update({
              id: listing?.id,
              geolocation,
              ...rest,
            });
          } catch (updateError) {
            console.error(`Failed to update listing ID: ${listing?.id}`, updateError);
          }
        })
      );

      // Wait before moving to the next batch
      await new Promise(resolve => setTimeout(resolve, delayBetweenBatches));
    }

    console.log('All listings updated successfully.');
  } catch (error) {
    console.error('Error updating listings:', error);
  }
};
