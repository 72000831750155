import axios from 'axios';
import { StatusCode } from '../enums/enums';
import { calculateBoundingBox, isArrayLength } from '../util/genericHelpers';

// ================ Action types ================ //

export const FETCH_ZIPCODE_REQUEST = 'app/location/FETCH_ZIPCODE_REQUEST';
export const FETCH_ZIPCODE_SUCCESS = 'app/location/FETCH_ZIPCODE_SUCCESS';
export const FETCH_ZIPCODE_ERROR = 'app/location/FETCH_ZIPCODE_ERROR';

// ================ Reducer ================ //

const initialState = { fetchZipcodeInProgress: false, fetchZipcodeError: null, zipcodeData: null };

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ZIPCODE_REQUEST:
      return {
        ...state,
        fetchZipcodeInProgress: true,
        fetchZipcodeError: null,
        zipcodeData: null,
      };
    case FETCH_ZIPCODE_SUCCESS:
      return {
        ...state,
        fetchZipcodeInProgress: false,
        fetchZipcodeError: null,
        zipcodeData: payload,
      };
    case FETCH_ZIPCODE_ERROR:
      return {
        ...state,
        fetchZipcodeInProgress: false,
        fetchZipcodeError: payload,
        zipcodeData: null,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const fetchZipcodeRequest = () => ({ type: FETCH_ZIPCODE_REQUEST });
export const fetchZipcodeSuccess = data => ({ type: FETCH_ZIPCODE_SUCCESS, payload: data });
export const fetchZipcodeError = error => ({
  type: FETCH_ZIPCODE_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //export const fetchZipcodeFromGeolocation = ({ lat, lng, radius }) => async (dispatch, _getState) => {

export const fetchZipcodeFromGeolocation = ({ lat, lng, radius }) => async (
  dispatch,
  _getState
) => {
  try {
    dispatch(fetchZipcodeRequest());

    // Convert radius to a number if it's a string
    const radiusInKm = parseFloat(radius);

    // Calculate bounding box (minLon, minLat, maxLon, maxLat)
    const { minLat, maxLat, minLon, maxLon } = calculateBoundingBox(lat, lng, radiusInKm);

    // Construct the URL for Mapbox reverse geocoding with longitude, latitude, and bbox
    const mapboxUrl = `${process.env.REACT_APP_MAPBOX_GEOCODE_API_URL}/search/geocode/v6/reverse?longitude=${lng}&latitude=${lat}&bbox=${minLon},${minLat},${maxLon},${maxLat}&types=postcode&access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`;

    // Make the API call to fetch postal code(s) within the bounding box
    const response = await axios.get(mapboxUrl);

    if (response.status === StatusCode.SUCCESS) {
      const { data } = response;
      if (isArrayLength(data.features)) {
        // Extract postal codes and full address
        const postalCodes = data.features.map(
          feature => feature.properties.postcode || feature.properties.name || 'Unknown'
        );

        dispatch(fetchZipcodeSuccess({ postalCodes }));
        return postalCodes;
      } else {
        throw new Error('No postal codes found within the specified radius.');
      }
    } else {
      throw new Error('Failed to fetch postal codes.');
    }
  } catch (error) {
    dispatch(fetchZipcodeError(error.message || error));
  }
};
