export const StatusCode = Object.freeze({
  SUCCESS: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  TOO_MANY_REQUESTS: 429,
});

export const ListingState = Object.freeze({
  DRAFT: 'draft',
  PENDING_APPROVAL: 'pendingApproval',
  PUBLISHED: 'published',
  CLOSED: 'closed',
});
